import { fetchWithOrgHeader } from "@/lib/fetchWithOrgHeader";
import type {
    DocumentCategoryDTO,
    DocumentDownloadUrlDTO,
    DocumentDTO,
    GenerateActivitiesPlanningDTO,
    GenerateMembersListDTO,
    PaginatedResponseDTO,
    UploadDocumentDTO,
} from "@/types/dto";
import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";

export interface FetchDocumentsOptions {
    page?: number;
    limit?: number;
    format?: "pdf" | "xlsx";
    category?: DocumentCategoryDTO;
    memberId?: string;
    archived?: boolean;
}

export const useDocumentsStore = defineStore("documents", () => {
    // State
    const documents = ref<DocumentDTO[]>([]);
    const myDocuments = ref<DocumentDTO[]>([]);
    const selectedDocument = ref<DocumentDTO | null>(null);
    const publicDocuments = ref<DocumentDTO[]>([]);
    const loading = ref(false);
    const error = ref<string | null>(null);
    const totalDocuments = ref<number>(0);
    const totalMyDocuments = ref<number>(0);

    // Computed
    const currentPage = computed(() => Math.ceil(documents.value.length / 20));
    const currentMyPage = computed(() => Math.ceil(myDocuments.value.length / 20));

    // Helper functions
    const buildQueryString = (options: Record<string, any>) => {
        return Object.entries(options)
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join("&");
    };

    const handleDocumentUpdate = (updatedDoc: DocumentDTO) => {
        const updateInList = (list: DocumentDTO[]) => {
            const index = list.findIndex(doc => doc.id === updatedDoc.id);
            if (index !== -1) {
                list[index] = updatedDoc;
            }
        };

        updateInList(documents.value);
        updateInList(myDocuments.value);

        if (selectedDocument.value?.id === updatedDoc.id) {
            selectedDocument.value = updatedDoc;
        }
    };

    // Actions
    async function fetchAllDocuments({
        page = 1,
        limit = 20,
        format,
        category,
        memberId,
        archived,
    }: FetchDocumentsOptions = {}): Promise<PaginatedResponseDTO<DocumentDTO>> {
        loading.value = true;
        error.value = null;

        try {
            const queryString = buildQueryString({ page, limit, format, category, memberId, archived });
            const response = await fetchWithOrgHeader<PaginatedResponseDTO<DocumentDTO>>(`/documents?${queryString}`);

            documents.value = response.data;
            totalDocuments.value = response.total ?? totalDocuments.value;
            return response;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function fetchAllROIDocuments({ page = 1, limit = 20 }): Promise<PaginatedResponseDTO<DocumentDTO>> {
        loading.value = true;
        error.value = null;

        const queryString = buildQueryString({ page, limit, category: "legal.roi" });

        try {
            const response = await fetchWithOrgHeader<PaginatedResponseDTO<DocumentDTO>>(`/documents?${queryString}`);
            return response;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function fetchMyDocuments({
        page = 1,
        limit = 20,
        category,
        memberId,
        archived,
    }: Omit<FetchDocumentsOptions, "format"> = {}): Promise<PaginatedResponseDTO<DocumentDTO>> {
        loading.value = true;
        error.value = null;

        try {
            const queryString = buildQueryString({ page, limit, category, memberId, archived });
            const response = await fetchWithOrgHeader<PaginatedResponseDTO<DocumentDTO>>(
                `/documents/mines?${queryString}`,
            );

            myDocuments.value = response.data;
            totalMyDocuments.value = response.total ?? totalMyDocuments.value;
            return response;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function fetchPublicDocuments(): Promise<DocumentDTO[]> {
        loading.value = true;
        error.value = null;

        try {
            const response = await fetchWithOrgHeader<DocumentDTO[]>(`/documents/public`);
            publicDocuments.value = response;
            return response;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function fetchDocumentById(id: string): Promise<DocumentDTO> {
        loading.value = true;
        error.value = null;

        try {
            const document = await fetchWithOrgHeader<DocumentDTO>(`/documents/${id}`);
            selectedDocument.value = document;
            return document;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function archiveDocument(id: string, archived: boolean): Promise<DocumentDTO> {
        loading.value = true;
        error.value = null;

        try {
            const document = await fetchWithOrgHeader<DocumentDTO>(`/documents/${id}`, {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ archived }),
            });

            handleDocumentUpdate(document);
            return document;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function uploadDocument({ file, title, category, scope }: UploadDocumentDTO): Promise<DocumentDTO> {
        loading.value = true;
        error.value = null;

        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("title", title);
            formData.append("category", category);
            formData.append("scope", scope);

            const document = await fetchWithOrgHeader<DocumentDTO>("/documents/upload", {
                method: "POST",
                body: formData,
            });

            documents.value = [document, ...documents.value];
            totalDocuments.value++;
            return document;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    function getDocumentDownloadUrl({ id, forceDownload, orgId }: DocumentDownloadUrlDTO): string {
        const API_URL = import.meta.env.VITE_API_URL;

        const baseUrl = `/api/documents/serve/${id}?orgId=${orgId}`;
        return forceDownload ? `${API_URL + baseUrl}&download=true` : API_URL + baseUrl;
    }

    async function generateMembersList({ establishmentId, isHolder, licenceStatus }: GenerateMembersListDTO) {
        loading.value = true;
        error.value = null;

        const queryString = buildQueryString({ establishmentId, isHolder, licenceStatus });

        try {
            const response = await fetchWithOrgHeader<DocumentDTO>(`/members/generate-members-list?${queryString}`, {
                method: "POST",
            });
            return response;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function generatePlanningActivitiesReport({ start, end, memberId }: GenerateActivitiesPlanningDTO) {
        loading.value = true;
        error.value = null;

        const queryString = buildQueryString({ start, end, memberId });

        try {
            const response = await fetchWithOrgHeader<DocumentDTO>(
                `/activities/generate-activities-planning?${queryString}`,
                {
                    method: "POST",
                },
            );
            return response;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    async function generateAttendanceList({ prestationId }: { prestationId: string }) {
        loading.value = true;
        error.value = null;

        try {
            const response = await fetchWithOrgHeader<DocumentDTO>(
                `/activities/generate-attendance-list/${prestationId}`,
                {
                    method: "POST",
                },
            );
            return response;
        } catch (err: any) {
            error.value = err.message;
            throw err;
        } finally {
            loading.value = false;
        }
    }

    // Reset store
    function $reset() {
        documents.value = [];
        myDocuments.value = [];
        selectedDocument.value = null;
        loading.value = false;
        error.value = null;
        totalDocuments.value = 0;
        totalMyDocuments.value = 0;
    }

    return {
        // State
        documents,
        myDocuments,
        selectedDocument,
        publicDocuments,
        loading,
        error,
        totalDocuments,
        totalMyDocuments,

        // Computed
        currentPage,
        currentMyPage,

        // Actions
        fetchAllDocuments,
        fetchAllROIDocuments,
        fetchMyDocuments,
        fetchPublicDocuments,
        fetchDocumentById,
        archiveDocument,
        uploadDocument,
        getDocumentDownloadUrl,
        generateMembersList,
        generatePlanningActivitiesReport,
        generateAttendanceList,
        $reset,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useDocumentsStore, import.meta.hot));
}
