<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { cn } from "@/lib/utils";
import { useAuthStore } from "@/stores/auth";
import { useMagicKeys } from "@vueuse/core";
import type { LucideIcon } from "lucide-vue-next";
import {
    Briefcase,
    Calendar,
    CalendarPlusIcon,
    ChevronDown,
    ChevronUp,
    Clock7Icon,
    ClockIcon,
    CoinsIcon,
    DollarSignIcon,
    Euro,
    EuroIcon,
    FileText,
    Headphones,
    Home,
    HomeIcon,
    LocateFixed,
    LogOut,
    MailsIcon,
    Menu,
    PlusIcon,
    SearchIcon,
    SettingsIcon,
    Undo2Icon,
    User,
    UserPlusIcon,
    Users,
} from "lucide-vue-next";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import ProfileAvatar from "./ProfileAvatar.vue";
import SheetLink from "./global/links/SheetLink.vue";
import LegalAcceptanceDialog from "./legal/LegalAcceptanceDialog.vue";
import NotificationsWarnings from "./warnings/NotificationsWarnings.vue";

interface BaseLink {
    title: string;
    icon: LucideIcon;
    href: string;
}

interface RegularLink extends BaseLink {
    isDropdown?: false;
}

interface DropdownLink extends BaseLink {
    isDropdown: true;
    subLinks: Array<{ title: string; href: string }>;
}

type LinkType = RegularLink | DropdownLink;

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const { isSystemAdmin, isSystemAdminOrganisation, isSupport, isMoreOrEqualToEstManager, isMoreOrEqualToOrgManager } =
    useUserPrivileges();
const userRole = computed(() => authStore.userRole);
const selfMember = computed(() => authStore.selfMember);
const selectedOrganization = computed(() => authStore.selectedOrganization);
const userOrganization = computed(() => authStore.userOrganization);

const displayProfileLink = computed(() => selectedOrganization.value?.id === userOrganization.value?.id);

const signOut = () => {
    authStore.signOut();
    router.push("/login");
};

const baseLinks: RegularLink[] = [
    { title: "Accueil", icon: Home, href: "/" },
    { title: "Documents", icon: FileText, href: "/documents" },
    { title: "Factures", icon: Euro, href: "/orders" },
    { title: "Communications", icon: Headphones, href: "/communications" },
];

const servicesLink = (isUser: boolean): LinkType =>
    isUser
        ? { title: "Services", icon: Briefcase, href: "/services" }
        : {
              title: "Services",
              icon: Briefcase,
              href: "/services-list",
          };

const membersLink: DropdownLink = {
    title: "Membres",
    icon: Users,
    href: "#",
    isDropdown: true,
    subLinks: [
        { title: "Tous", href: "/members/all" },
        { title: "Titulaires", href: "/members/holders" },
        { title: "Participants", href: "/members/participants" },
    ],
};

const configurationLink = (establishmentId: string, organizationId: string): RegularLink => ({
    title: "Configuration",
    icon: SettingsIcon,
    href:
        userRole.value === "est-manager"
            ? `/configuration/establishments/${establishmentId}`
            : `/configuration/organizations/${organizationId}`,
});

const isServicesDropdownOpen = ref(false);
const isMembersDropdownOpen = ref(false);

const toggleServicesDropdown = () => {
    isServicesDropdownOpen.value = !isServicesDropdownOpen.value;
};

const toggleMembersDropdown = () => {
    isMembersDropdownOpen.value = !isMembersDropdownOpen.value;
};

const isLinkActive = (href: string) => {
    if (href === "/") {
        return route.path === "/";
    }
    return route.path.startsWith(href);
};

const isDropdownActive = (paths: string[]) => {
    return paths.some(path => route.path.startsWith(path));
};

const getAdditionalLinks = computed(() => {
    const additionalLinks: LinkType[] = [];

    if (isMoreOrEqualToEstManager.value) {
        additionalLinks.push();
    }

    return additionalLinks;
});

const getAdditionalActionsLinks = computed(() => {
    const additionalLinks: LinkType[] = [];

    if (isMoreOrEqualToEstManager.value) {
        additionalLinks.push(
            {
                title: "Créer un établissement",
                icon: PlusIcon,
                href: "/establishments/create",
            },
            {
                title: "Créer un service",
                icon: PlusIcon,
                href: "/services/create",
            },
            {
                title: "Créer une période",
                icon: CalendarPlusIcon,
                href: "/periods/create",
            },
            {
                title: "Ajouter un membre",
                icon: UserPlusIcon,
                href: "/members/create",
            },
        );
    }

    return additionalLinks;
});

const links = computed((): LinkType[] => {
    if (isSystemAdminOrganisation.value && isSupport.value) {
        return [
            { title: "Organisations", icon: HomeIcon, href: "/organizations" },
            { title: "Contacter l'administration", icon: MailsIcon, href: "/contact-support" },
        ];
    }

    if (isSystemAdminOrganisation.value && isSystemAdmin.value) {
        return [
            { title: "Organisations", icon: HomeIcon, href: "/organizations-list" },
            { title: "Facturation", icon: EuroIcon, href: "/billing" },
            { title: "Communication", icon: Headphones, href: "/communications" },
            configurationLink(authStore.currentEstablishmentId, authStore.selectedOrganization!.id),
        ];
    }

    const isUser = userRole.value === "user";
    const isHolder = userRole.value === "user" && selfMember.value?.isHolder;
    const isEstManager = userRole.value === "est-manager";
    const isOrgManager = userRole.value === "org-manager" || userRole.value === "admin";

    if (isUser && !isHolder) {
        return [
            baseLinks[0],
            servicesLink(true),
            { title: "Famille", icon: Users, href: "/delegations" },
            { title: "Jetons de récupération", icon: CoinsIcon, href: "/recovery-tokens" },
            { title: "Historique des activités", icon: Clock7Icon, href: "/registry" },
            ...baseLinks.slice(1),
        ];
    }

    if (isHolder) {
        return [
            baseLinks[0],
            servicesLink(true),
            { title: "Participants", icon: Users, href: "/members/participants" },
            { title: "Historique des activités", icon: Clock7Icon, href: "/registry" },
            ...baseLinks.slice(3),
        ];
    }

    const commonLinks: LinkType[] = [
        baseLinks[0],
        servicesLink(false),
        membersLink,
        { title: "Périodes", icon: Calendar, href: "/periods" },
    ];

    if (isEstManager) {
        return [
            ...commonLinks,
            ...baseLinks.slice(1, 2),
            ...baseLinks.slice(3),
            { title: "Jetons de récupération", icon: CoinsIcon, href: "/recovery-tokens" },
            { title: "Comptabilité", icon: DollarSignIcon, href: "/accountability" },
            { title: "Heures prestées", icon: ClockIcon, href: "/hours-worked" },
            configurationLink(authStore.currentEstablishmentId, authStore.selectedOrganization!.id),
        ];
    }

    if (isMoreOrEqualToOrgManager.value && !isSystemAdminOrganisation.value) {
        const links = [
            ...commonLinks,
            { title: "Jetons de récupération", icon: CoinsIcon, href: "/recovery-tokens" },
            { title: "Établissements", icon: LocateFixed, href: "/establishments" },
            { title: "Comptabilité", icon: DollarSignIcon, href: "/accountability" },
            { title: "Heures prestées", icon: ClockIcon, href: "/hours-worked" },
            ...baseLinks.slice(1, 2),
            ...baseLinks.slice(3),
            configurationLink(authStore.currentEstablishmentId, authStore.selectedOrganization!.id),
        ];

        if (isSupport.value) {
            return links.filter(l => l.title !== "Comptabilité");
        } else {
            return links;
        }
    }

    return baseLinks;
});

// Command palette
const isCommandOpen = ref(false);

const toggleCommand = () => {
    isCommandOpen.value = !isCommandOpen.value;
};

const keys = useMagicKeys();
const CmdK = keys["Cmd+K"];

function handleOpenChange() {
    if (CmdK.value) {
        toggleCommand();
    }
}

watch(CmdK, v => {
    if (v) handleOpenChange();
});

const allCommandItems = computed(() => {
    const regularItems = links.value.flatMap(link => {
        if (link.isDropdown) {
            return link.subLinks.map(subLink => ({
                title: `${link.title} - ${subLink.title}`,
                href: subLink.href,
                icon: link.icon,
            }));
        }
        return [
            {
                title: link.title,
                href: link.href,
                icon: link.icon,
            },
        ];
    });

    const additionalItems = getAdditionalLinks.value.map(link => ({
        title: link.title,
        href: link.href,
        icon: link.icon,
    }));

    return [...regularItems, ...additionalItems];
});

const navigateToItem = (href: string) => {
    router.push(href);
    isCommandOpen.value = false;
};

const handleGoBackAdmin = () => {
    if (!isSystemAdmin.value) return;
    authStore.exitSysAdminMode();
    router.push({ name: "organizations" });
};

// Sheet control
const isSheetOpen = ref(false);

const closeSheet = () => {
    isSheetOpen.value = false;
};
</script>

<template>
    <aside
        :class="
            cn(
                'left-0 top-0 hidden h-screen w-72 flex-col items-start justify-between gap-6 px-12 py-10 text-white lg:flex',
                !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark',
            )
        ">
        <router-link :to="'/'" class="mx-auto inline-block w-full max-w-[100px]" title="Retour à l'accueil">
            <img src="/logo/light-small.png" class="mx-auto inline-block w-full max-w-[100px]" alt="Logo Rainbow Top" />
        </router-link>
        <nav class="flex grow overflow-y-auto">
            <ul class="flex grow flex-col gap-4">
                <li v-for="link in links" :key="link.title">
                    <template v-if="link.isDropdown">
                        <div
                            @click="link.title === 'Services' ? toggleServicesDropdown() : toggleMembersDropdown()"
                            class="cursor-pointer">
                            <div class="group flex flex-row flex-nowrap items-center gap-2 text-white">
                                <component
                                    :is="link.icon"
                                    :class="
                                        isDropdownActive(link.subLinks.map(sl => sl.href))
                                            ? 'bg-secondary rounded-full'
                                            : 'bg-transparent'
                                    "
                                    class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full p-2 transition-colors" />
                                <span>
                                    {{ link.title }}
                                </span>
                                <component
                                    :is="
                                        (link.title === 'Services' ? isServicesDropdownOpen : isMembersDropdownOpen)
                                            ? ChevronUp
                                            : ChevronDown
                                    "
                                    class="size-4" />
                            </div>
                        </div>
                        <ul
                            v-if="
                                (link.title === 'Services' && isServicesDropdownOpen) ||
                                (link.title === 'Membres' && isMembersDropdownOpen)
                            "
                            class="ml-11 mt-2 space-y-2">
                            <li v-for="subLink in link.subLinks" :key="subLink.title">
                                <router-link :to="subLink.href" class="group flex items-center gap-2 text-white">
                                    <div
                                        :class="isLinkActive(subLink.href) ? 'bg-secondary' : 'bg-transparent'"
                                        class="group-hover:bg-secondary/60 size-2 rounded-full transition-colors"></div>
                                    <span
                                        :class="
                                            cn(
                                                'group-hover:text-secondary/60 transition-colors',
                                                isLinkActive(subLink.href) ? 'text-secondary' : 'text-white',
                                            )
                                        ">
                                        {{ subLink.title }}
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </template>
                    <router-link
                        v-else
                        :to="link.href"
                        class="group flex flex-row flex-nowrap items-center gap-2 text-white">
                        <component
                            :is="link.icon"
                            :class="isLinkActive(link.href) ? 'bg-secondary rounded-full' : 'bg-transparent'"
                            class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full p-2 transition-colors" />
                        <span>
                            {{ link.title }}
                        </span>
                    </router-link>
                </li>
            </ul>
        </nav>
        <footer
            :class="
                cn('flex w-full grow flex-col items-start justify-end', {
                    'gap-8': (!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation,
                    'gap-3': !isSystemAdminOrganisation && isSystemAdmin,
                })
            ">
            <div v-if="isMoreOrEqualToOrgManager && !isSystemAdminOrganisation" class="w-full">
                <NotificationsWarnings />
            </div>

            <div v-if="!isSystemAdminOrganisation && isSystemAdmin" class="flex w-full flex-col items-start gap-2">
                <div class="flex flex-col">
                    <p class="text-sm">Connecté à</p>
                    <p class="text-sm">
                        {{ selectedOrganization?.name }}
                    </p>
                </div>
            </div>

            <div class="flex w-full items-center gap-4" v-if="displayProfileLink">
                <router-link
                    :to="{ name: 'profile' }"
                    class="group flex w-full flex-grow flex-row flex-nowrap items-center gap-4 text-white">
                    <ProfileAvatar class="group-hover:bg-secondary/80 transition-colors" />
                    <span
                        class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors group-hover:text-white/80">
                        Mon profil
                    </span>
                </router-link>
                <Button variant="ghost" size="icon" @click="toggleCommand" class="hover:text-secondary text-white">
                    <SearchIcon :size="16" />
                </Button>
            </div>

            <Button
                v-if="(!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation"
                variant="secondary"
                @click="signOut"
                class="flex w-full items-center justify-center gap-2 py-2">
                <LogOut :size="20" />
                Se déconnecter
            </Button>
            <Button
                v-else-if="!isSystemAdminOrganisation && isSystemAdmin"
                variant="secondary"
                @click="handleGoBackAdmin"
                class="flex w-full items-center justify-center gap-2 py-2">
                <Undo2Icon :size="20" />
                Quitter
            </Button>
        </footer>
    </aside>
    <Sheet v-model:open="isSheetOpen">
        <div
            :class="
                cn(
                    'flex items-center justify-between p-4 lg:hidden',
                    !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark',
                )
            ">
            <router-link :to="{ name: 'index' }" class="flex items-center gap-2">
                <img
                    src="/logo/circle-white.png"
                    class="inline-flex size-10 items-center object-contain lg:hidden"
                    alt="Logo Rainbow Top" />
            </router-link>

            <div class="flex items-center gap-6">
                <SheetTrigger as-child>
                    <Button
                        class="flex shrink-0 items-center gap-2 bg-transparent px-0 lg:hidden"
                        @click="isSheetOpen = true">
                        Menu
                        <Menu :size="30" />
                        <span class="sr-only"> Ouvrir le menu de navigation </span>
                    </Button>
                </SheetTrigger>

                <!-- Logout button -->
                <Button
                    v-if="(!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation"
                    variant="secondary"
                    @click="signOut"
                    class="flex items-center justify-center gap-2 py-2">
                    <LogOut :size="20" />
                </Button>
                <Button
                    v-else-if="!isSystemAdminOrganisation && isSystemAdmin"
                    variant="secondary"
                    @click="handleGoBackAdmin"
                    class="flex items-center justify-center gap-2 py-2">
                    <Undo2Icon :size="20" />
                </Button>
            </div>
        </div>
        <SheetContent
            side="left"
            :class="
                cn('bg-primary border-primary', !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark')
            ">
            <nav class="flex h-full flex-col gap-10 text-lg font-medium">
                <router-link :to="{ name: 'index' }" class="flex items-center gap-2">
                    <img
                        src="/logo/light-small.png"
                        class="mx-auto inline-block w-full max-w-[100px]"
                        alt="Logo Rainbow Top" />
                </router-link>
                <ul class="flex shrink flex-col gap-4">
                    <li v-for="link in links" :key="link.title">
                        <SheetLink
                            :link="link"
                            :isServicesDropdownOpen="isServicesDropdownOpen"
                            :isMembersDropdownOpen="isMembersDropdownOpen"
                            :toggleServicesDropdown="toggleServicesDropdown"
                            :toggleMembersDropdown="toggleMembersDropdown"
                            :isLinkActive="isLinkActive"
                            @close="closeSheet" />
                    </li>
                </ul>

                <footer class="flex grow flex-col items-start justify-end gap-4">
                    <router-link
                        v-if="displayProfileLink"
                        :to="{ name: 'profile' }"
                        class="group flex flex-row flex-nowrap items-center gap-2 text-white"
                        @click="closeSheet">
                        <component
                            :is="User"
                            class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full bg-transparent p-2 transition-colors" />
                        <span
                            class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
                            Mon profil
                        </span>
                    </router-link>

                    <Button
                        variant="secondary"
                        @click="signOut"
                        class="flex w-full items-center justify-center gap-2 py-2">
                        <LogOut :size="20" />
                        <p
                            class="group-hover:decoration-primary text-lg font-medium underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
                            Se déconnecter
                        </p>
                    </Button>
                </footer>
            </nav>
        </SheetContent>
    </Sheet>

    <LegalAcceptanceDialog />

    <CommandDialog v-model:open="isCommandOpen">
        <Command>
            <CommandInput placeholder="Tapez une commande ou recherchez..." />
            <CommandList>
                <CommandEmpty>Aucun résultat trouvé.</CommandEmpty>
                <CommandGroup heading="Suggestions">
                    <CommandItem
                        v-for="item in allCommandItems"
                        :key="item.href"
                        :value="item.title"
                        @select="() => navigateToItem(item.href)">
                        <component :is="item.icon" class="mr-2 h-4 w-4" />
                        <span>{{ item.title }}</span>
                    </CommandItem>
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup heading="Actions">
                    <CommandItem
                        v-for="link in getAdditionalActionsLinks"
                        :key="link.title"
                        :value="link.title"
                        @select="() => navigateToItem(link.href)">
                        <component :is="link.icon" class="mr-2 h-4 w-4" />
                        <span>{{ link.title }}</span>
                    </CommandItem>
                    <CommandItem @select="signOut" :value="'Se déconnecter'">
                        <LogOut class="mr-2 h-4 w-4" />
                        <span>Se déconnecter</span>
                    </CommandItem>
                </CommandGroup>
            </CommandList>
        </Command>
    </CommandDialog>
</template>
