export interface CustomError {
    message: string;
    statusCode: number;
    statusMessage: string;
}

export const getErrorMessage = (error: CustomError | any) => {
    let message = "Une erreur inconnue est survenue.";
    if (error) {
        // La requête a été faite et le serveur a répondu avec un statut qui ne soit pas dans la plage de 2xx
        const status = error.statusCode || error.status;
        switch (status) {
            case 400:
                message = "Les données fournies sont invalides.";
                break;
            case 401:
                message = "Identifiants non valides. Veuillez réessayer.";
                break;
            case 403:
                message = "Vous n’avez pas la permission d’effectuer cette action.";
                break;
            case 404:
                message = "Les données fournies sont introuvables.";
                break;
            case 500:
                message = "Erreur interne du serveur.";
                break;
            default:
                message = `Erreur inattendue (Code ${status}).`;
        }
    } else {
        // Quelque chose s'est produit lors de la mise en place de la requête qui a déclenché une erreur
        message = "Une erreur inattendue est survenue.";
    }
    return message;
};
