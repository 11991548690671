<script lang="ts" setup>
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useAuthStore } from "@/stores/auth";
import { useOrganizationsStore } from "@/stores/organizations";
import { CircleAlertIcon, ThumbsUpIcon } from "lucide-vue-next";
import { computed, onMounted } from "vue";
import { Button } from "../ui/button";

const { isOrgManager, isMoreOrEqualToOrgManager, isSystemAdmin } = useUserPrivileges();

const authStore = useAuthStore();
const organizationsStore = useOrganizationsStore();

const currentOrganizationId = computed(() => authStore.selectedOrganization?.id);
const organizationWarnings = computed(() => organizationsStore.organizationWarnings);

const publicWarnings = computed(() => {
    return organizationWarnings.value?.filter(warning => warning.isPublic);
});

const adminWarnings = computed(() => {
    return organizationWarnings.value;
});

const warningsToDisplay = computed(() => {
    if (!currentOrganizationId.value && !isMoreOrEqualToOrgManager.value) return [];

    if (isOrgManager.value) {
        return publicWarnings.value;
    } else if (isSystemAdmin.value) {
        return adminWarnings.value;
    } else {
        return [];
    }
});

onMounted(() => {
    if (!currentOrganizationId.value || !isMoreOrEqualToOrgManager.value) return;

    organizationsStore.fetchOrganizationWarnings(currentOrganizationId.value);
});
</script>

<template>
    <HoverCard :open-delay="200">
        <HoverCardTrigger as-child>
            <Button variant="ghost" class="relative flex w-full items-center gap-2 overflow-visible bg-white/10">
                <!-- Bullet ping for warnings -->
                <div class="absolute -right-1 -top-1" v-if="warningsToDisplay && warningsToDisplay.length > 0">
                    <span class="relative flex h-3 w-3">
                        <span
                            class="absolute inline-flex h-full w-full animate-ping rounded-full bg-orange-400 opacity-75"></span>
                        <span class="relative inline-flex h-3 w-3 rounded-full bg-orange-500"></span>
                    </span>
                </div>

                <CircleAlertIcon :size="16" />
                Avertissements {{ organizationWarnings }}
            </Button>
        </HoverCardTrigger>
        <HoverCardContent :side="'right'">
            <div class="flex flex-col gap-2 divide-y">
                <p class="body-lg font-medium">Avertissements</p>
                <div
                    v-if="warningsToDisplay && warningsToDisplay.length > 0"
                    v-for="(warning, i) in warningsToDisplay"
                    :key="i"
                    class="flex items-start gap-2 pt-2">
                    <p class="body-sm text-muted-foreground">
                        {{ warning.message }}
                    </p>
                </div>
                <div v-else class="flex items-start gap-2 pt-2">
                    <ThumbsUpIcon :size="16" class="text-muted-foreground" />
                    <p class="body-sm text-muted-foreground">Aucun avertissement</p>
                </div>
            </div>
        </HoverCardContent>
    </HoverCard>
</template>
