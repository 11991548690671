import { getErrorMessage } from "@/helpers/errorHelper";
import { fetchWithOrgHeader } from "@/lib/fetchWithOrgHeader"; // Import the custom fetch function
import type { MemberDTO, UpdateUserDTO, UserDTO } from "@/types/dto";
import { StorageSerializers, useLocalStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { toast } from "vue-sonner";
import { useAuthStore } from "./auth";

export const useMeStore = defineStore("me", () => {
    // Other stores
    const authStore = useAuthStore();

    // States
    const user = useLocalStorage<UserDTO | null>("user", null, { serializer: StorageSerializers.object });
    const selfMember = useLocalStorage<MemberDTO | null>("selfMember", null, { serializer: StorageSerializers.object });
    const delegatedMembers = ref<MemberDTO[]>([]);
    const representatives = ref<UserDTO[]>([]);
    const loading = ref(false);

    // Getters
    const allMembers = computed(() => delegatedMembers.value.filter(Boolean) as MemberDTO[]);

    // Actions
    async function fetchCurrentUser() {
        loading.value = true;
        try {
            const data = await fetchWithOrgHeader("/me/user");
            user.value = data;
            loading.value = false;
            return data;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function fetchSelfMember() {
        loading.value = true;
        try {
            const data = await fetchWithOrgHeader("/me/member");
            selfMember.value = data;
            loading.value = false;
            return data;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function updateSelfMember(updateData: UpdateUserDTO) {
        loading.value = true;
        try {
            const data = await fetchWithOrgHeader("/me/member", {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updateData),
            });
            selfMember.value = data;
            loading.value = false;
            toast.success("Vos informations ont été mises à jour avec succès.");
            return data;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function fetchDelegatedMembers() {
        loading.value = true;
        try {
            const data = await fetchWithOrgHeader("/me/delegated");
            console.log(data);
            delegatedMembers.value = data;
            loading.value = false;
            return data;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function createDelegatedMember(memberData: MemberDTO) {
        loading.value = true;
        try {
            const newMember = await fetchWithOrgHeader("/me/delegated", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(memberData),
            });
            delegatedMembers.value.push(newMember);
            loading.value = false;
            toast.success("Le membre délégué a été créé avec succès.");
            return newMember;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function updateDelegatedMember(memberId: string, updateData: UpdateUserDTO) {
        loading.value = true;
        try {
            const updatedMember = await fetchWithOrgHeader(`/me/delegated/${memberId}`, {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updateData),
            });
            const index = delegatedMembers.value.findIndex(member => member.id === memberId);
            if (index !== -1) {
                delegatedMembers.value[index] = updatedMember;
            }
            loading.value = false;
            toast.success("Le membre délégué a été mis à jour avec succès.");
            return updatedMember;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function removeDelegatedMember(memberId: string) {
        loading.value = true;
        try {
            await fetchWithOrgHeader(`/me/delegated/${memberId}`, {
                method: "DELETE",
            });
            delegatedMembers.value = delegatedMembers.value.filter(member => member.id !== memberId);
            loading.value = false;
            toast.success("Le membre délégué a été supprimé avec succès.");
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function fetchRepresentatives() {
        loading.value = true;
        try {
            const data = await fetchWithOrgHeader("/me/representatives");
            representatives.value = data;
            loading.value = false;
            return data;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function addRepresentative(userData: UserDTO) {
        loading.value = true;
        try {
            const newRepresentative = await fetchWithOrgHeader("/me/representatives", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userData),
            });
            representatives.value.push(newRepresentative);
            loading.value = false;
            toast.success("Le représentant a été ajouté avec succès.");
            return newRepresentative;
        } catch (error) {
            loading.value = false;
            throw handleFetchError(error);
        }
    }

    async function acceptROI() {
        loading.value = true;
        try {
            await fetchWithOrgHeader("/me/conditions/accept-roi", {
                method: "PATCH",
            });
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            loading.value = false;
        }
    }

    async function acceptLegalMentions() {
        loading.value = true;
        try {
            await fetchWithOrgHeader("/me/conditions/accept-legal-mentions", {
                method: "PATCH",
            });
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            loading.value = false;
        }
    }

    function handleFetchError(error: any) {
        console.error(error);
        toast.error(error.message ?? getErrorMessage(error));
        return error;
    }

    return {
        user,
        selfMember,
        delegatedMembers,
        representatives,
        loading,
        allMembers,
        fetchCurrentUser,
        fetchSelfMember,
        updateSelfMember,
        fetchDelegatedMembers,
        createDelegatedMember,
        updateDelegatedMember,
        removeDelegatedMember,
        fetchRepresentatives,
        addRepresentative,
        acceptROI,
        acceptLegalMentions,
    };
});
